<template>
  <div class="resource-img" :style="styles">
    <CommonScrambleImg
      v-if="isScrambleImg"
      :src="img"
      :style="{ objectFit: props.objectFit }"
      :alt="props.alt"
      :image-scale="props.imageScale"
      crossorigin="anonymous"
      @load="onLoadResourceImg"
      @error="onErrorResourceImg"
    />
    <CommonImg
      v-else
      :src="img"
      :style="{ objectFit: props.objectFit }"
      :alt="props.alt"
      crossorigin="anonymous"
      :loader-url="props.loaderUrl"
      @load="onLoadResourceImg"
      @error="onErrorResourceImg"
    />
  </div>
</template>

<script lang="ts" setup>
import { CSSProperties } from 'vue';
import { scrambleImgTargets } from '~/configs/resource';

const { $resourceTextureUrl } = useNuxtApp();

const props = withDefaults(defineProps<{
  asset: string,
  partition: number,
  cd: string,
  extension?: string,
  subDirectory?: string,
  width?: string,
  height?: string,
  alt?: string,
  imageScale?: number,
  objectFit?: 'contain' | 'cover' | 'fill' | 'none',
  loaderUrl?: string,
}>(), {
  extension: 'png',
  subDirectory: undefined,
  width: '',
  height: '',
  alt: '',
  imageScale: 1,
  objectFit: 'fill',
  loaderUrl: '',
});
const getImageUrl = () => {
  return $resourceTextureUrl(props.asset, props.partition, props.cd, props.extension, props.subDirectory);
};
const img = ref<string>(getImageUrl());
watch(props, () => {
  img.value = getImageUrl();
});

const toSize = (n: number | string) => {
  return typeof n === 'number' ? `${n}px` : n;
};
const styles = computed((): CSSProperties => {
  return {
    width: props.width ? toSize(props.width) : undefined,
    height: props.height ? toSize(props.height) : undefined,
  };
});
const isScrambleImg = computed(() => {
  if (!useRuntimeConfig().public.resourceScrambleImg.enable) {
    return false;
  }
  return scrambleImgTargets.includes(props.asset);
});

interface Emits {
  (e: 'onLoadResourceImg'): void;
  (e: 'onErrorResourceImg'): void;
}

const emit = defineEmits<Emits>();

// 画像読み込み完了時
const onLoadResourceImg = () => {
  emit('onLoadResourceImg');
};

// 画像読み込み失敗時
const onErrorResourceImg = () => {
  emit('onErrorResourceImg');
};
</script>

<style lang="scss" scoped>
.resource-img {
  display: inline-block;

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
