<template>
  <div>
    <img ref="originImg" :style="{ visibility: disable ? 'hidden' : 'visible'}" @load="onLoad" @error="onError">
    <canvas ref="decryptCanvas" />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { decryptImg } from '~/libs/decryptImg';

interface Props {
  src: string,
  width?: string,
  imageScale?: number,
}

const props = withDefaults(defineProps<Props>(), {
  width: undefined,
  imageScale: 1,
});

interface Emits {
  (e: 'load'): void;
  (e: 'error'): void;
}
const emit = defineEmits<Emits>();

const disable = ref<boolean>(false);
const originImg = ref();
const decryptCanvas = ref<HTMLCanvasElement>();

// 読み込み完了にて自動で表示
const onLoad = () => {
  disable.value = false;
  emit('load');
};
// エラー時に自動で非表示
const onError = () => {
  disable.value = true;
  emit('error');
};

onMounted(() => {
  // 親要素のサイズに合わせる。
  const width = props.width ?? originImg.value.parentElement.offsetWidth;
  decryptImg(props.src, decryptCanvas.value, width, 0, props.imageScale);
});
</script>
