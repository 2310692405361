export const decryptImg = async (originImgSrc, decryptCanvas, width, height, scale = 1) => {
  await drawCanvas({
    imgSrc: originImgSrc,
    canvas: decryptCanvas,
    width,
    height,
    shuffleSize: 10,
    scale,
  });
  forbidLeftClick(decryptCanvas);
};

export const releaseCanvas = (canvas) => {
  canvas.width = 1;
  canvas.height = 1;
  const ctx = canvas.getContext('2d');
  ctx && ctx.clearRect(0, 0, 1, 1);
};

async function drawCanvas ({ imgSrc, canvas, width, height, shuffleSize, scale }) {
  try {
    let hexData = await getHexdata(imgSrc);
    let img = await decryptHex(hexData, shuffleSize);
    // 縮小したサイズでcanvasに描画すると拡大したときに画質が落ちるので、元画像サイズで描画し、styleで縮小する。
    const imageScale = scale ?? 1;
    canvas.width = img.width * imageScale;
    canvas.height = img.height * imageScale;

    let ctx = canvas.getContext('2d');
    await ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

    // width or height どちらか、もしくは両方の指定が必要。
    if (width) {
      canvas.style.width = width + 'px';
    } else {
      const styleWidth = height / img.height * img.width;
      canvas.style.width = styleWidth + 'px';
    }
    if (height) {
      canvas.style.height = height + 'px';
    } else {
      const styleHeight = width / img.width * img.height;
      canvas.style.height = styleHeight + 'px';
    }
    // メモリ解放
    hexData = null;
    img = null;
    ctx = null;
  } catch (e) {
    console.log(e);
  }
}

async function getHexdata (imgSrc) {
  const request = new Request(imgSrc);
  let response = '';
  await fetch(request, {
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain'
    },
    mode: 'cors'
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('[decryptImg] Network response was not OK');
      }
      return response.text();
    })
    .then((text) => {
      response = text;
    })
    .catch((error) => {
      console.log('[decryptImg] ajax request error.' + error);
    });
  return response;
}

async function decryptHex (hexData, shuffleSize) {
  // eslint-disable-next-line no-async-promise-executor
  return await new Promise(async (resolve, reject) => {
    const hexArray = [];
    for (let i = 0; i < hexData.length; i += 2) {
      hexArray.push(hexData.substring(i, i + 2));
    }
    const sortedArray = shuffleArray(hexArray, shuffleSize);
    const decimalArray = [];
    for (let i = 0; i < sortedArray.length; i++) {
      const decimalInt = parseInt(sortedArray[i], 16);
      decimalArray.push(decimalInt);
    }
    const typedArr = new Uint8Array(decimalArray);
    const blob = new Blob([typedArr], { type: 'image/jpeg' });

    if ('createImageBitmap' in window) {
      const img = await createImageBitmap(blob);
      resolve(img);
    } else {
      // iOS14ではcreateImageBitmap()が使えない。
      const img = new Image();
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = function () {
        img.src = String(reader.result);
        img.onload = () => {
          resolve(img);
        };
        img.onerror = (e) => {
          console.log('[decryptImg] img.onload() error.' + e);
          reject(e);
        };
      };
    }
  });
}

function shuffleArray (array, shuffleSize) {
  const length = Math.ceil(array.length / shuffleSize);
  const slieced = new Array(length).fill().map((_, i) =>
    array.slice(i * shuffleSize, (i + 1) * shuffleSize)
  );
  const result = [];
  for (let i = 0; i < slieced.length; i++) {
    const reversed = slieced[i].reverse();
    result.push(reversed);
  }
  return result.flat();
}

function forbidLeftClick (element) {
  const cancelEvent = function (e) {
    e.preventDefault();
  };
  element.addEventListener('contextmenu', cancelEvent);
  element.addEventListener('dragstart', cancelEvent);
}
