<template>
  <img ref="imgTag" :src="loaderUrl && !isLoaded ? loaderUrl : src" :style="{ visibility: disable ? 'hidden' : 'visible'}" @load="onLoad" @error="onError">
</template>

<script lang="ts" setup>

interface Emits {
  (e: 'load'): void;
  (e: 'error'): void;
}
const emit = defineEmits<Emits>();

interface Props {
  src: string,
  loaderUrl?: string,
}
defineProps<Props>();

const disable = ref<boolean>(false);
const isLoaded = ref<boolean>(false);

// 読み込み完了にて自動で表示
const onLoad = () => {
  disable.value = false;
  isLoaded.value = true;
  emit('load');
};
// エラー時に自動で非表示
const onError = () => {
  disable.value = true;
  emit('error');
};
const imgTag = ref<HTMLImageElement>();
onBeforeUnmount(() => {
  // onUnmountedだとrefが解放されてしまうため一時的に変数に設定
  if (imgTag.value) {
    let img: HTMLImageElement | undefined = imgTag.value;
    setTimeout(() => {
      if (img) {
        // NOTE: iOSのメモリ解放が甘いため手動でガベージコレクションしやすいように変更
        // useLog('common/img').debug(`unmount img ${img.src}`);
        img.src = '';
        img.width = 1;
        img.height = 1;
        img = undefined;
      }
    }, 500);
  }
});
</script>
